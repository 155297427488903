body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

table {
    margin-top: 20px;
}

th div span {
    font-weight: bold !important;
    color: #6482B0;
}

td {
    padding: 5px !important;
}

tr:nth-child(even) {
    background: #EEE
}

tr:nth-child(odd) {
    background: #FFF
}

.btn-custom {
    position: relative !important;
    top: 1px !important;
    padding: 0 15px !important;
    font-size: 14px !important;
    margin-right: 5px !important;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
}

.modal-main {
    text-align: left;
    position: fixed;
    background: white;
    /*width: 50%;*/
    min-width: 360px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-main-radio div[class^='RaFormInput-input-'],
.modal-main-radio .simple-form .ra-input .radio
{
    width: 350px;
}

.simple-form.form-refund .MuiFormControl-root,
.simple-form.form-refund .MuiCardContent-root div[class^='RaFormInput-input-'] {
    width: 100%;
}

.modal-main-medium {
    min-width: 500px;
}

.modal h2 {
    padding-left: 30px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 24px;
    font-family: Roboto, sans-serif;
    font-weight: normal;
}

.modal h3 {
    padding-left: 30px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 18px;
    font-family: Roboto, sans-serif;
    font-weight: normal;
}

.modal .btnClose {
    cursor: pointer;
    float: right;
    position: relative;
    top: 20px;
    right: 15px;
    height: 25px;
    width: 25px;
    display: inline-block;
    border-radius: 50%;
    border: none;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    outline: none;
    padding: 0;
}

.modal .btnClose > span {
    display: inline-block;
    width: 25px;
}

.modal .btnClose:hover {
    opacity: 1;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.simple-form .aor-input {
    padding: 0 15px;
}

.simple-form .aor-input > div {
    width: 100%;
}

.simple-form .aor-input-warehouse_id > div {
    width: 100% !important;
}

.simple-form .aor-input-warehouse_id > div.fullWidth-true {
    width: 100% !important;
}

.simple-form .aor-input-warehouse_id > div.fullWidth-false {
    width: 256px !important;
}


.simple-form .aor-input-itemId {
    display: none;
}

.modal-main > form > div:nth-child(2) {
    padding: 5px 6px !important;
    height: auto !important;
}

.modal-main > form > div:nth-child(2) > div {
    margin: 0 !important;
}

.hideToolBar {
    display: none !important;
}

input[type="number"] {
    padding: 5px;
}

.tbl_tab3 thead tr th:first-child {
    display: none;
}

.textRed {
    color: #dc3545 !important;
}

.textRed_stock div,
.textRed_stock > div > div > div:nth-child(2) {
    color: #dc3545 !important;
}

.a-customize svg {
    fill: rgb(0, 188, 212) !important;
}

#export_warehouse_button span {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.link-css-class {
    box-shadow: none !important;
}

.link-css-class svg {
    fill: rgb(0, 188, 212) !important;
}

.link-css-class span {
    color: rgb(0, 188, 212) !important;
}

/* Common class */
.px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-16 {
    padding-left: 16px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pl-24 {
    padding-left: 24px !important;
}

.pr-24 {
    padding-right: 24px !important;
}

.px-24 {
    padding-left: 24px !important;
    padding-right: 24px !important;
}
.py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.mr-5 {
    margin-right: 5px !important;
}
.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.right{
    float: right;
}

.text-disabled {
    color: rgba(0, 0, 0, 0.55);
}

.text-danger {
    color: #dc3545;
}

.text-center{
    text-align: center;
}
.text-size-4em {
    font-size: 4em!important;
}
.w100{
    width: 100%!important;
}
.dropdown-arr{
    position: relative;
    top: -3px;
}

.text-lc,
.text-lc span {
    text-transform: lowercase !important;
}

.sku-text {
    display: inline-block;
    width: max-content;
    max-width: 190px;
}

.modal-edit-address .edit-page .title,
.modal-edit-address .edit-page button {
    display: none;
}

.modal-edit-address .edit-page div {
    box-shadow: none !important;
    border: none;
}
.modal-edit-product .display-none {
    display: none!important;
}
.modal-edit-product input[type="number"] {
    padding: 23px 12px 10px;
}
.modal-edit-product.has-duplicate-btn .dialog-footer {
    position: relative;
}
.modal-edit-product.has-duplicate-btn .dialog-footer .btn-add-model {
    position: absolute;
    left: 8px;
    top: 8px;
    border: 1px solid #2196f3;
}
.order-histories table thead tr th:first-child{ display: none}

.order-histories {
    width: 100%;
}
.order-histories table{
    margin-top: 0;
}
.order-histories.render2Block {
    margin-right: 16px;
    width: calc(50% - 8px);
}
.order-histories.render2Block.fulfilmentBox {
    margin-right: 0px;
}

.order-histories > .buttonTitle{
    display: inline-block;
    width: 100%;
    background: #dedede;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 0;
    text-align: left;
    text-transform: capitalize;
}

.order-histories table tr {
    height: 30px!important;
}
.order-histories table th,
.order-histories table td{
    height: auto!important;
    padding-left: 10px!important;
    padding-right: 0!important;
    overflow: auto!important;
    white-space: normal!important;
}

.detailed_order_log{
margin-top: -50px!important;
margin-right: 10px!important;
}

.customclass {
padding: 0px;
position: relative;
width: 100%;;
outline: currentcolor none medium;
background-color: rgba(0, 0, 0, 0);
color: rgba(0, 0, 0, 0.87);
cursor: inherit;
font: inherit;
height: 100%;
box-sizing: border-box;
margin-top: 14px;
border: medium none;
border-bottom: solid 1px rgb(224, 224, 224);
}

.formShipment > form.tabbed-form > div:first-child > div > div > button {
display: none !important;
}

.formShipment table.tbl_tab3 tbody tr td:nth-child(4) > div,
.formReships table.tbl_tab3 tbody tr td:nth-child(4) > div {
width: 100% !important;
}

.orderDetail-summary .card {
width: 50%;
min-height: 50px;
float: left;
padding: 30px 0 0 0;
box-sizing: border-box;
font-size: 16px;
line-height: 10px;
position: relative;
background-color: transparent;
cursor: auto;
}

.orderDetail-summary .card label {
position: absolute;
line-height: 15px;
top: 30px;
color: rgba(0, 0, 0, 0.5);
transform: scale(0.75) translate(0px, -28px);
transform-origin: left top 0px;
}

.clearAll::after {
display: block;
content: "";
clear: both;
}

.yellow-icon svg > path {
color: #FF9400;
}

.red-icon svg > path {
color: #dc3545;
}

/* Popup form */
.sf-order-items .simple-form .aor-input > div {
    width: 100% !important;
}

/* swal popup custom */
.btn-show-history span {
    padding-left: 0 !important;
    text-transform: initial !important;
    top: -8px;
}

.swal-text-left {
    text-align: left;
}

.swal-order-history {
    min-width: 700px;
    text-align: left;
    /*width: auto !important;*/
}

.swal-order-history .swal-content {
    overflow-y: auto;
    max-height: 50vh;
}
.swal-order-history .swal-content p {
    font-size: 13px;
    display: flex;
    margin: 0;
    padding: 3px;
    border-bottom: 1px solid rgb(224, 224, 224);
}

.swal-order-history .swal-content p:hover {
    background: #EEE;
}

.swal-order-history .swal-content p > span {
    width: 140px;
    min-width: 140px;
}

.swal-content ul { padding-left: 1.5rem}
.swal-content ul li { margin-bottom: 10px}
.swal-content ul li:last-child { margin-bottom: 0}

.sales-report-form form.simple-form > div:nth-child(2) > div > div {
    margin: 10px 5px !important;
}

.sales-report-form .aor-input-channel_id span[role="menuitem"] > div > div {
    padding: 5px 0 !important;
}

.customInput.shipForm {
    margin-left: 2%;
}

.customInput {
    border: none;
    border-bottom: 1px solid rgb(224, 224, 224);
    width: 90%;
    padding: 6px 5px;
}

.shipmentTracking {
    margin-top: 0;
}

span.uppercase{
    text-transform: uppercase;
}

.modal-size .modal-main {
    min-width: 500px;
}

.modal-size .modal-main .simple-form .radio{
    width: 100%;
}

.customInventoryInput  input[type='number'],
.custom_number_input input[type='number']
{
  min-height: 30px;
  padding: 27px 12px 10px
}

.RaLabeled-label-relative{
    position: relative !important;
    padding-bottom: 5px !important;
}

.inventory-gird-description{
    max-width: 300px;
    display: block;
}
.product-gird-description {
    max-width: 33vw;
    display: block;
}

div[data-testid^='dropzone'] {
    text-align: left !important;
    background-color: #dedede;
}

.ra-rich-text-input .ql-editor{
    min-height: 200px;
}

.login-page {
    background-color: #000;
    width: 100%;
    height: 100vh;
    max-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-page .content {
    padding: 50px;
}

.login-page .content img.logo {
    display: block;
    max-width: 400px;
    width: 100%;
}

.login-page .content .googleLoginContent {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    border: 1px solid #2E7BC1;
    background-color: #fff;
    border-radius: 5px;
    transition: 0.5s;
    font-weight: 400;
    font-size: 18px;
    margin-top: 20px;
    color: #444;
    font-family: 'Roboto', sans-serif;
}

.login-page .content .googleLoginContent:hover {
    cursor: pointer;
    background-color: rgba(33, 150, 243, 0.5);
}

.login-page .content .googleLoginContent img {
    padding-right: 0.25rem;
}

.login-page .content .googleLoginContent span {
    padding-left: 25px;
    font-family: 'Roboto', sans-serif;
}

#main-content table.MuiTable-root .MuiTableBody-root tr td > span {
    padding-left: 10px;
}

#main-content table .string-dots,
#main-content table .string-dots > p {
    display: inline-block;
    width: 15vw;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
#main-content table .string-dots > p {
    margin-top: 5px;
    margin-bottom: 0;
    line-height: 1;
}